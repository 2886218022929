import React from 'react';

const Github = ({ width = 16, height = 16, color = '#000' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.5 34.7" width={width} height={height} className="card-icon">
    <path
      fill={color}
      d="M33.2 8.9a17.7 17.7 0 0 0-6.5-6.5 17.4 17.4 0 0 0-9-2.4A17.4 17.4 0 0 0 9 2.4a17.7 17.7 0 0 0-6.5 6.5A17.4 17.4 0 0 0 0 17.8a17.3 17.3 0 0 0 3.4 10.4 17.4 17.4 0 0 0 8.8 6.4 1 1 0 0 0 .9-.1 1 1 0 0 0 .3-.7v-1.3-2h-.6a6.8 6.8 0 0 1-1.3.2 9.8 9.8 0 0 1-1.6-.2 3.6 3.6 0 0 1-1.5-.7 3 3 0 0 1-1-1.4l-.3-.6a5.8 5.8 0 0 0-.7-1.1 2.8 2.8 0 0 0-1-1h-.2a1.7 1.7 0 0 1-.3-.3 1.3 1.3 0 0 1-.2-.3l.1-.3a1.5 1.5 0 0 1 .7-.1H6a3.4 3.4 0 0 1 1.1.6 3.7 3.7 0 0 1 1.1 1.2A4 4 0 0 0 9.5 28a2.7 2.7 0 0 0 1.5.5 6.6 6.6 0 0 0 1.4-.1 4.6 4.6 0 0 0 1-.4 3.8 3.8 0 0 1 1.1-2.4 15.8 15.8 0 0 1-2.3-.4 9.4 9.4 0 0 1-2.2-.9A6.2 6.2 0 0 1 8 22.8 7.5 7.5 0 0 1 7 20.3a11.6 11.6 0 0 1-.5-3.4A6.8 6.8 0 0 1 8.3 12a6.2 6.2 0 0 1 .1-4.7 3.2 3.2 0 0 1 2 .3 14 14 0 0 1 2 .9l1 .6a16.7 16.7 0 0 1 8.8 0l.9-.6a12.5 12.5 0 0 1 2.1-1 3 3 0 0 1 1.9-.2 6.2 6.2 0 0 1 .2 4.7 6.8 6.8 0 0 1 1.8 4.8 11.7 11.7 0 0 1-.5 3.4 7.2 7.2 0 0 1-1.2 2.5 6.5 6.5 0 0 1-1.9 1.5 9.5 9.5 0 0 1-2.1.9 15.8 15.8 0 0 1-2.4.4 4.1 4.1 0 0 1 1.2 3.3v4.9a1 1 0 0 0 .3.7 1 1 0 0 0 .9.1 17.4 17.4 0 0 0 8.8-6.4 17.3 17.3 0 0 0 3.3-10.4 17.4 17.4 0 0 0-2.3-9z"
      data-name="Path 291"
    />
  </svg>

  // <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}>
  //   <path
  //     fill={color}
  //     fillRule="evenodd"
  //     d="M12.672.668a.75.75 0 00-1.345 0L8.27 6.865l-6.838.994a.75.75 0 00-.416 1.279l4.948 4.823-1.168 6.811a.75.75 0 001.088.791L12 18.347l6.117 3.216a.75.75 0 001.088-.79l-1.168-6.812 4.948-4.823a.75.75 0 00-.416-1.28l-6.838-.993L12.672.668z"
  //   ></path>
  // </svg>
);

export default Github;
